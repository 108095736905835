import React from 'react';

import type { BlockchainNetwork, Community, PostQuestInput, TaskType } from '@zealy/utils';
import { BLOCKCHAIN_NETWORKS, getSubdomain, TOKENS_BY_CHAIN } from '@zealy/utils';

import { FIELDS } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/Fields';
import { API } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/Fields/API';
import { Domino } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/Fields/Domino';
import { SelectQuestField } from '#app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/Fields/SelectQuestField';
import { taskClaimMap } from '#components/Tasks/components';

import type { TypeOption } from '../../app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/Fields/TypeField';
import { BASE_TASK_PREVIEW } from '../../app/cw/[subdomain]/(app)/questboard/admin/[questId]/_components/QuestEditor/Tasks/Task/TaskPreview';
import { TASK_COLORS } from './TaskColors';
import { TASK_ICONS } from './TaskIcons';

const TEXT_OPTIONS: TypeOption[] = [
  {
    value: 'text',
    icon: TASK_ICONS.text({}),
  },
  {
    value: 'number',
    icon: TASK_ICONS.number({}),
  },
  {
    value: 'url',
    icon: TASK_ICONS.url({}),
  },
];

const TWITTER_OPTIONS: TypeOption[] = [
  {
    value: 'tweetReact',
  },
  {
    value: 'twitterFollow',
  },
  {
    value: 'tweet',
  },
  {
    value: 'twitterSpace',
  },
];

const POLL_OPTIONS: TypeOption[] = [
  {
    value: 'poll',
    icon: TASK_ICONS.poll({}),
  },
  {
    value: 'quiz',
    icon: TASK_ICONS.quiz({}),
  },
];

const ON_CHAIN_OPTIONS: TypeOption[] = [
  {
    value: 'nft',
    icon: TASK_ICONS.nft({}),
  },
  {
    value: 'token',
    icon: TASK_ICONS.token({}),
  },
  // {
  //   value: 'onChain',
  //   icon: TASK_ICONS.onChain({}),
  // },
];

export const BASE_TYPES = {
  onChain: 'onChain',
  nft: 'nft',
  token: 'token',
  partnership: 'partnership',
  api: 'api',
  text: 'text',
  number: 'text',
  url: 'text',
  date: 'text',
  visitLink: 'visitLink',
  opinion: 'opinion',
  invites: 'invites',
  discord: 'discord',
  telegram: 'telegram',
  poll: 'poll',
  quiz: 'quiz',
  file: 'file',
  twitterSpace: 'twitterSpace',
  twitterFollow: 'twitterFollow',
  tweet: 'tweet',
  tweetReact: 'tweetReact',
  tiktok: 'tiktok',
} as const;

export type Identification =
  | 'email'
  | 'twitter'
  | 'discord'
  | 'tiktok'
  | 'wallet'
  | BlockchainNetwork
  | Community['blockchain'];

interface TaskField {
  name: string;
  field:
    | (typeof FIELDS)[keyof typeof FIELDS]
    | (({ name, namespace }: { name: string; namespace: string }) => React.JSX.Element | null);
  shouldRender?: (settings: Record<string, unknown>) => boolean;
  [key: string]: any;
}

export type BaseTask = (typeof BASE_TYPES)[TaskType];

type TaskSettings = PostQuestInput['tasks'][0];

export type BaseTaskFields = Record<
  TaskType,
  {
    fields?: TaskField[];
    defaultValue: TaskSettings;
    identifications?: (settings: Record<string, unknown>) => Identification[];
    preview: ({ index, type }: { index: number; type: TaskType }) => JSX.Element;
    claim: (props: any) => JSX.Element | null;
    color: string;
    icon: (typeof TASK_ICONS)[keyof typeof TASK_ICONS];
  }
>;

export const TASK_CONFIG: BaseTaskFields = {
  nft: {
    icon: TASK_ICONS.nft,
    color: TASK_COLORS.nft,
    preview: BASE_TASK_PREVIEW.nft,
    claim: taskClaimMap.get('nft'),
    identifications: ({ network }) => [network as BlockchainNetwork],
    defaultValue: {
      type: 'nft',
      settings: {
        contract: '',
        network: 'eth-mainnet',
        minBalance: '1' as unknown as number,
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: ON_CHAIN_OPTIONS,
      },
      {
        name: 'network',
        field: FIELDS.select,
        options: BLOCKCHAIN_NETWORKS,
      },
      {
        name: 'contract',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
      },
      {
        name: 'minBalance',
        field: FIELDS.text,
        type: 'number',
      },
      {
        name: 'tokenId',
        field: FIELDS.text,
        optional: true,
      },
    ],
  },
  token: {
    icon: TASK_ICONS.token,
    color: TASK_COLORS.token,
    preview: BASE_TASK_PREVIEW.token,
    claim: taskClaimMap.get('token'),
    identifications: ({ network }) => [network as BlockchainNetwork],
    defaultValue: {
      type: 'token',
      settings: {
        contract: '0',
        network: 'eth-mainnet',
        minBalance: '1' as unknown as number,
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: ON_CHAIN_OPTIONS,
      },
      {
        name: 'network',
        field: FIELDS.select,
        options: BLOCKCHAIN_NETWORKS,
      },
      {
        name: 'contract',
        field: FIELDS.command,
        options: ({ network }: { network: BlockchainNetwork }) =>
          TOKENS_BY_CHAIN[network]?.map(token => ({
            value: token.contractAddress,
            label: token.symbol,
          })) ?? [],
      },
      {
        name: 'minBalance',
        field: FIELDS.text,
        type: 'number',
      },
    ],
  },
  onChain: {
    icon: TASK_ICONS.onChain,
    color: TASK_COLORS.onChain,
    preview: BASE_TASK_PREVIEW.onChain,
    identifications: ({ network }) => [network as BlockchainNetwork],
    claim: taskClaimMap.get('onChain'),
    defaultValue: {
      type: 'onChain',
      // @ts-ignore
      settings: {
        title: '',
        description: '',
        endpoint: '',
        network: 'eth-mainnet',
        identifications: ['wallet'] as const,
      },
    },
    fields: [
      {
        name: 'domino',
        field: Domino,
      },
      {
        name: 'network',
        field: FIELDS.select,
        options: [
          'eth-mainnet',
          'arb-mainnet',
          'base-mainnet',
          'bnb-mainnet',
          'polygon-mainnet',
          'opt-mainnet',
          'avalanche-mainnet',
        ],
      },
      {
        name: 'endpoint',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
      },
      {
        name: '',
        field: API,
        namespace: 'quest.type.api.fields.tests',
      },
    ],
  },
  partnership: {
    icon: TASK_ICONS.partnership,
    color: TASK_COLORS.partnership,
    preview: BASE_TASK_PREVIEW.partnership,
    claim: taskClaimMap.get('partnership'),
    defaultValue: {
      type: 'partnership',
      settings: {
        subdomain: '',
      },
    },
    fields: [
      {
        name: 'subdomain',
        field: FIELDS.text,
        leftIcon: (
          <span className="!h-auto body-component-md text-placeholder min-w-fit leading-tight -mr-100">
            zealy.io/c/
          </span>
        ),
        setOnBlur: true,
        trim: true,
      },
    ],
  },
  api: {
    icon: TASK_ICONS.api,
    color: TASK_COLORS.api,
    preview: BASE_TASK_PREVIEW.api,
    identifications: values => [
      ...(values.identifications as Identification[]).filter(
        type => !(type === 'wallet' && 'network' in values),
      ),
      ...('network' in values ? [values.network as BlockchainNetwork] : []),
    ],
    claim: taskClaimMap.get('api'),
    defaultValue: {
      type: 'api',
      settings: {
        title: '',
        description: '',
        endpoint: '',
        apiKey: '',
        identifications: [],
        redirectUrl: '',
      },
    },
    fields: [
      {
        name: 'endpoint',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
      },
      {
        name: 'apiKey',
        field: FIELDS.text,
        optional: true,
      },
      {
        name: 'identifications',
        field: FIELDS.checkbox,
        options: ['zealy-connect', 'email', 'twitter', 'discord', 'wallet'],
        namespace: 'api.fields.identifications',
        className: 'grid grid-cols-2',
      },
      {
        name: 'network',
        placeholder: 'ethereum',
        field: FIELDS.select,
        options: BLOCKCHAIN_NETWORKS,
        shouldRender: (values =>
          (values?.identifications as string[]).includes('wallet')) as TaskField['shouldRender'],
      },
      {
        name: 'redirectUrl',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
        shouldRender: (values =>
          (values?.identifications as string[]).includes(
            'zealy-connect',
          )) as TaskField['shouldRender'],
      },
      {
        name: '',
        field: API,
        namespace: 'quest.type.api.fields.tests',
      },
    ],
  },
  number: {
    icon: TASK_ICONS.number,
    color: TASK_COLORS.number,
    preview: BASE_TASK_PREVIEW.text,
    claim: taskClaimMap.get('number'),
    defaultValue: {
      type: 'number',
      settings: {
        title: '',
        description: '',
        autoValidated: false,
      },
    },
  },
  url: {
    icon: TASK_ICONS.url,
    color: TASK_COLORS.url,
    preview: BASE_TASK_PREVIEW.text,
    claim: taskClaimMap.get('url'),
    defaultValue: {
      type: 'url',
      settings: {
        title: '',
        description: '',
        autoValidated: false,
      },
    },
  },
  date: {
    icon: TASK_ICONS.date,
    color: TASK_COLORS.date,
    preview: BASE_TASK_PREVIEW.text,
    claim: taskClaimMap.get('date'),
    defaultValue: {
      type: 'date',
      settings: {
        title: '',
        description: '',
        autoValidated: false,
      },
    },
  },
  text: {
    icon: TASK_ICONS.text,
    color: TASK_COLORS.text,
    preview: BASE_TASK_PREVIEW.text,
    claim: taskClaimMap.get('text'),
    defaultValue: {
      type: 'text',
      settings: {
        title: '',
        description: '',
        autoValidated: false,
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: TEXT_OPTIONS,
      },
      {
        name: 'autoValidated',
        field: FIELDS.switch,
      },
      {
        name: 'correctAnswer',
        field: FIELDS.text,
        shouldRender: (values => !!values?.autoValidated) as TaskField['shouldRender'],
        optional: true,
      },
    ],
  },
  visitLink: {
    icon: TASK_ICONS.visitLink,
    color: TASK_COLORS.visitLink,
    preview: BASE_TASK_PREVIEW.visitLink,
    claim: taskClaimMap.get('visitLink'),
    defaultValue: {
      type: 'visitLink',
      settings: {
        linkUrl: '',
      },
    },
    fields: [
      {
        name: 'linkUrl',
        field: FIELDS.text,
      },
    ],
  },
  invites: {
    icon: TASK_ICONS.invites,
    color: TASK_COLORS.invites,
    preview: BASE_TASK_PREVIEW.invites,
    claim: taskClaimMap.get('invites'),
    defaultValue: {
      type: 'invites',
      settings: {
        minInviteUserCount: 10,
        mandatoryQuests: [],
      },
    },
    fields: [
      {
        name: 'minInviteUserCount',
        field: FIELDS.text,
        type: 'number',
      },
      {
        name: 'mandatoryQuests',
        field: SelectQuestField,
      },
      {
        name: 'requiredXp',
        field: FIELDS.link,
        link: `/cw/${getSubdomain()}/settings/security`,
      },
    ],
  },
  discord: {
    icon: TASK_ICONS.discord,
    color: TASK_COLORS.discord,
    preview: BASE_TASK_PREVIEW.discord,
    claim: taskClaimMap.get('discord'),
    identifications: () => ['discord'],
    defaultValue: {
      type: 'discord',
      settings: {
        inviteUrl: '',
      },
    },
    fields: [
      {
        name: 'inviteUrl',
        field: FIELDS.text,
      },
    ],
  },
  telegram: {
    icon: TASK_ICONS.telegram,
    color: TASK_COLORS.telegram,
    preview: BASE_TASK_PREVIEW.telegram,
    claim: taskClaimMap.get('telegram'),
    defaultValue: {
      type: 'telegram',
      settings: {
        inviteUrl: '',
      },
    },
    fields: [
      {
        name: 'inviteUrl',
        field: FIELDS.text,
      },
    ],
  },
  opinion: {
    icon: TASK_ICONS.opinion,
    color: TASK_COLORS.opinion,
    preview: BASE_TASK_PREVIEW.opinion,
    claim: taskClaimMap.get('opinion'),
    defaultValue: {
      type: 'opinion',
      settings: {
        title: '',
        description: '',
        scaleType: 'numerical',
        step: {
          start: 0,
          end: 10,
        },
        // @ts-ignore
        starCount: 5,
      },
    },
    fields: [
      {
        name: 'scaleType',
        field: FIELDS.type,
        options: [
          {
            value: 'numerical',
            label: 'opinion.fields.scaleType.numerical',
          },
          {
            value: 'stars',
            label: 'opinion.fields.scaleType.stars',
          },
        ],
      },
      {
        name: 'starCount',
        field: FIELDS.text,
        shouldRender: (values => values?.scaleType === 'stars') as TaskField['shouldRender'],
      },
      {
        name: 'step',
        field: FIELDS.range,
        subfields: ['start', 'end'],
        shouldRender: (values => values?.scaleType === 'numerical') as TaskField['shouldRender'],
      },
      {
        name: 'label',
        field: FIELDS.range,
        subfields: ['start', 'end'],
        optional: true,
        shouldRender: (values => values?.scaleType === 'numerical') as TaskField['shouldRender'],
      },
    ],
  },
  poll: {
    icon: TASK_ICONS.poll,
    color: TASK_COLORS.poll,
    preview: BASE_TASK_PREVIEW.poll,
    claim: taskClaimMap.get('poll'),
    defaultValue: {
      type: 'poll',
      settings: {
        title: '',
        description: '',
        autoValidated: true,
        multipleSelection: false,
        shuffleOptionsOrder: false,
        otherOption: false,
        verticalAlignement: false, // remove?
        withImage: false,
        options: [
          {
            label: '',
          },
        ],
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: POLL_OPTIONS,
      },
      {
        name: 'autoValidated',
        field: FIELDS.switch,
      },
      {
        name: 'multipleSelection',
        field: FIELDS.switch,
      },
      // {
      //   name: 'shuffleOptionsOrder',
      //   field: FIELDS.switch,
      // },
      // {
      //   name: 'withImage',
      //   field: FIELDS.switch,
      // },
      // {
      //   name: 'verticalAlignement',
      //   field: FIELDS.switch,
      // },
      {
        name: 'otherOption',
        field: FIELDS.switch,
      },
    ],
  },
  quiz: {
    icon: TASK_ICONS.quiz,
    color: TASK_COLORS.quiz,
    preview: BASE_TASK_PREVIEW.quiz,
    claim: taskClaimMap.get('quiz'),
    defaultValue: {
      type: 'quiz',
      settings: {
        title: '',
        description: '',
        // @ts-ignore
        autoValidate: true,
        multipleSelection: false,
        shuffleOptionsOrder: false,
        verticalAlignement: false, // remove?
        withImage: false,
        options: [
          {
            label: '',
          },
        ],
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: POLL_OPTIONS,
      },
      {
        name: 'correctAnswer',
        field: FIELDS.link,
      },
      {
        name: 'multipleSelection',
        field: FIELDS.switch,
      },
      // Always do this instead of an option you need to enable?
      // {
      //   name: 'shuffleOptionsOrder',
      //   field: FIELDS.switch,
      // },
      // TODO: enable once we have the design or just have an add image button on the tag instead of a switch?
      // {
      //   name: 'withImage',
      //   field: FIELDS.switch,
      // },
      // Always do this on options with a lot of text instead of an option you need to enable?
      // {
      //   name: 'verticalAlignement',
      //   field: FIELDS.switch,
      // },
    ],
  },
  file: {
    icon: TASK_ICONS.file,
    color: TASK_COLORS.file,
    preview: BASE_TASK_PREVIEW.file,
    claim: taskClaimMap.get('file'),
    defaultValue: {
      type: 'file',
      settings: {
        restrictFileType: true,
        fileTypes: ['image'],
        autoValidated: false,
        maxFileCount: 1,
        maxFileSize: 10000000,
      },
    },
    fields: [
      {
        name: 'restrictFileType',
        field: FIELDS.switch,
      },
      {
        name: 'fileTypes',
        field: FIELDS.checkbox,
        shouldRender: (values => !!values.restrictFileType) as TaskField['shouldRender'],
        options: [
          'document',
          'presentation',
          'spreadsheet',
          'image',
          'drawing',
          'video',
          'audio',
          'archive',
        ],
        namespace: 'file.fields.fileTypes',
        className: 'grid grid-cols-2',
      },
      {
        name: 'maxFileCount',
        field: FIELDS.text,
        optional: true,
      },
      {
        name: 'autoValidated',
        field: FIELDS.switch,
      },
    ],
  },
  twitterSpace: {
    icon: TASK_ICONS.twitterSpace,
    color: TASK_COLORS.twitterSpace,
    preview: BASE_TASK_PREVIEW.twitterSpace,
    claim: taskClaimMap.get('twitterSpace'),
    identifications: () => ['twitter'],
    defaultValue: {
      type: 'twitterSpace',
      settings: {
        spaceUrl: '',
        password: '',
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: TWITTER_OPTIONS,
      },
      {
        name: 'spaceUrl',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
      },
      {
        name: 'password',
        field: FIELDS.text,
        optional: true,
      },
    ],
  },
  twitterFollow: {
    icon: TASK_ICONS.twitterFollow,
    color: TASK_COLORS.twitterFollow,
    preview: BASE_TASK_PREVIEW.twitterFollow,
    claim: taskClaimMap.get('twitterFollow'),
    identifications: () => ['twitter'],
    defaultValue: {
      type: 'twitterFollow',
      settings: {
        username: '',
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: TWITTER_OPTIONS,
      },
      {
        name: 'username',
        field: FIELDS.text,
        leftIcon: <span className="!h-auto">@</span>,
        setOnBlur: true,
        trim: true,
      },
    ],
  },
  tweet: {
    icon: TASK_ICONS.tweet,
    color: TASK_COLORS.tweet,
    preview: BASE_TASK_PREVIEW.tweet,
    claim: taskClaimMap.get('tweet'),
    identifications: () => ['twitter'],
    defaultValue: {
      type: 'tweet',
      settings: {
        tweetWords: [],
        defaultTweet: '',
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: TWITTER_OPTIONS,
      },
      {
        name: 'tweetWords',
        field: FIELDS.keywords,
      },
      {
        name: 'defaultTweet',
        field: FIELDS.text,
        textarea: true,
        optional: true,
      },
    ],
  },
  tweetReact: {
    icon: TASK_ICONS.tweetReact,
    color: TASK_COLORS.tweetReact,
    preview: BASE_TASK_PREVIEW.tweetReact,
    claim: taskClaimMap.get('tweetReact'),
    identifications: () => ['twitter'],
    defaultValue: {
      type: 'tweetReact',
      settings: {
        actions: [],
        tweetUrl: '',
      },
    },
    fields: [
      {
        name: 'type',
        field: FIELDS.type,
        options: TWITTER_OPTIONS,
      },
      {
        name: 'tweetUrl',
        field: FIELDS.text,
        setOnBlur: true,
        trim: true,
      },
      {
        name: 'actions',
        field: FIELDS.checkbox,
        options: ['like', 'retweet', 'reply'],
      },
    ],
  },
  tiktok: {
    icon: TASK_ICONS.tiktok,
    color: TASK_COLORS.tiktok,
    preview: BASE_TASK_PREVIEW.tiktok,
    claim: taskClaimMap.get('tiktok'),
    identifications: () => ['tiktok'],
    defaultValue: {
      type: 'tiktok',
      settings: {
        descriptionWords: [],
        minViewCount: 0,
      },
    },
    fields: [
      {
        name: 'descriptionWords',
        field: FIELDS.keywords,
      },
      {
        name: 'minViewCount',
        field: FIELDS.text,
        type: 'number',
      },
    ],
  },
};

export type TaskGroups =
  | 'visitLink'
  | 'invites'
  | 'api'
  | 'file'
  | 'poll'
  | 'quiz'
  | 'text'
  | 'number'
  | 'url'
  | 'opinion'
  | 'discord'
  | 'tweetReact'
  | 'telegram'
  | 'tiktok'
  | 'twitter'
  | 'partnership'
  | 'nft'
  | 'token'
  | 'onChain';

export const TASK_GROUPS: {
  [key: string]: TaskGroups[];
} = {
  actions: ['visitLink', 'invites', 'api', 'partnership'],
  answers: ['file', 'poll', 'quiz', 'text', 'number', 'url', 'opinion'],
  social: ['discord', 'twitter', 'telegram', 'tiktok'],
  onChain: ['nft', 'token', 'onChain'],
};
