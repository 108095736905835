import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { defineChain } from 'viem';
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  chiliz,
  cronos,
  fantom,
  gnosis,
  linea,
  mainnet,
  moonbeam,
  optimism,
  palm,
  polygon,
  polygonZkEvm,
  zksync,
} from 'wagmi/chains';

import { envConfig } from '#app/config';

const gunzTestnet = defineChain({
  id: 49321,
  name: 'GUNZ Testnet',
  nativeCurrency: {
    name: 'GUN',
    symbol: 'GUN',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/rpc'],
      webSocket: ['wss://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/ws'],
    },
    public: {
      http: ['https://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/rpc'],
      webSocket: ['wss://rpc.gunz.dev/ext/bc/ryk9vkvNuKtewME2PeCgybo9sdWXGmCkBrrx4VPuZPdVdAak8/ws'],
    },
  },
  blockExplorers: {
    default: {
      name: 'GUNZ Scan',
      url: 'https://testnet.gunzscan.io',
    },
  },
  contracts: {},
  testnet: true,
});

export const config = getDefaultConfig({
  appName: 'Zealy',
  projectId: envConfig.walletconnect.projectId,
  chains: [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    bsc,
    linea,
    avalanche,
    fantom,
    cronos,
    palm,
    gnosis,
    chiliz,
    moonbeam,
    zksync,
    polygonZkEvm,
    gunzTestnet,
  ],
  ssr: true,
});
